<template>
  <div>
    <b-row>
      <b-col
        v-for="talkCenter in items"
        :key="talkCenter.id"
        lg="4"
        md="6"
      >
        <b-card
          class="card-transaction"
          no-body
        >
          <b-card-header>
            <b-card-title>{{ talkCenter.name }}</b-card-title>
            <b-dropdown
              variant="link"
              no-caret
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="editTalkOperationTime(talkCenter)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t('management') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>
          <b-card-body>
            <b-list-group>
              <b-list-group-item
                v-for="talkOperationTime in talkCenter.talkOperationTimeList"
                :key="talkOperationTime.id"
              >
                {{ talkOperationTime.start_hour }} : {{ talkOperationTime.start_min }} ~ {{ talkOperationTime.end_hour }} : {{ talkOperationTime.end_min }}
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>

      </b-col>
    </b-row>

    <b-modal
      ref="edit-talk-operation-time-modal"
      cancel-variant="outline-secondary"
      ok-title="Update"
      cancel-title="Close"
      centered
      no-close-on-backdrop
      size="xl"
      title="Edit Form"
      @ok="editHandleOk"
      @close="resetFormData"
      @cancel="resetFormData"
    >
      <validation-observer ref="simpleRules">
        <b-form @submit.stop.prevent="editHandleSubmit">
          <b-row>
            <b-col
              md="12"
              class="mb-md-0 mb-2"
            >
              <b-col
                md="12"
                class="mb-md-0 mb-2"
              >
                <table class="table b-table table-bordered">
                  <tr
                    v-for="(item, index) in formData.talkOperationTimeList"
                    :key="index"
                  >
                    <td>Start ~ end</td>
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('start_hour')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="formData.talkOperationTimeList[index].start_hour"
                          :placeholder="$t('start_hour')"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('start_min')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="formData.talkOperationTimeList[index].start_min"
                          :placeholder="$t('start_min')"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      ~
                    </td>
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('end_hour')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="formData.talkOperationTimeList[index].end_hour"
                          :placeholder="$t('end_hour')"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('end_min')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="formData.talkOperationTimeList[index].end_min"
                          :placeholder="$t('end_min')"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </td>
                    <td>
                      <b-button-group>
                        <b-button
                          variant="info"
                          @click="addList(index, formData.talkOperationTimeList)"
                        >
                          Insert
                        </b-button>
                        <b-button
                          variant="warning"
                          :disabled="formData.talkOperationTimeList.length == 1"
                          @click="removeList(index, formData.talkOperationTimeList)"
                        >
                          Delete
                        </b-button>
                      </b-button-group>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>

import {
  required,
} from '@validations'

import {
  BRow,
  BCol,
  BButton,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BModal,
  BForm,
  BFormInput,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TalkCenter from '@/models/TalkCenter'

export default {
  name: 'AdminTalkOperationTime',
  components: {
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BButton,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BModal,
    BForm,
    BFormInput,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      required,
      items: [],
      formData: {
        id: '',
        name: '',
        talkOperationTimeList: [
          {
            id: '',
            start_hour: '',
            start_min: '',
            end_hour: '',
            end_min: '',
            talk_center_id: '',
          },
        ],
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const response = await TalkCenter.list()
      this.items = response.data.data
    },
    resetFormData() {
      this.formData = {
        id: '',
        name: '',
        talkOperationTimeList: [
          {
            id: '',
            start_hour: '',
            start_min: '',
            end_hour: '',
            end_min: '',
            talk_center_id: '',
          },
        ],
      }
    },
    async editTalkOperationTime(data) {
      this.resetFormData()

      try {
        const response = await TalkCenter.view(data.id)
        this.formData = response.data
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        })
      }

      this.$refs['edit-talk-operation-time-modal'].show()
    },
    editHandleOk(e) {
      // Prevent modal from closing
      e.preventDefault()
      // Trigger submit handler
      this.editHandleSubmit()
    },
    async editHandleSubmit() {
      const validator = await this.$refs.simpleRules.validate()

      if (validator) {
        try {
          const response = await TalkCenter.updateTakeOperationTimes(this.formData)
          this.$swal({
            title: 'Success!',
            text: response.data,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.loadData()
          this.$refs['edit-talk-operation-time-modal'].hide()
          this.resetFormData()
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: e.message,
            },
          })
        }
      }
    },
    addList(index, list) {
      list.splice(index + 1, 0, {
        id: '',
        start_hour: '',
        start_min: '',
        end_hour: '',
        end_min: '',
        talk_center_id: '',
      })
    },
    removeList(index, list) {
      if (list.length > 1) {
        list.splice(index, 1)
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
